<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <v-spacer></v-spacer>
        <span class="text-h5">Importação de Seriais</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text align="center" justify="center" style="height: 150px; overflow-x: auto;">
          <div class="my-2 image-container">
            <span>Arquivo CSV ou TXT</span>
            <FileInput v-model="form.files" />
          </div>
      </v-card-text>
      <v-card-actions>
          <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
          <v-spacer />
          <v-btn color="success" @click="salvar" :loading="loading">
            <v-icon>mdi-content-save</v-icon>&nbsp;Importar
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileInput from '@/components/FileInput';

export default {
  name: 'ProductForm',

  components: {
    FileInput,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: Object
    },
  },

  data: () => ({
    loading: false,
    form: {},
  }),

  mounted() {
    this.form = this.formData;
  },

  watch: {
    formData(value) {
      this.form = value ? JSON.parse(JSON.stringify(value)) : {};
    },

    visible(v) {
      !v && (this.form = {});
    }
  },

  methods: {
    salvar() {
      this.loading = true;
      this.$http.post('seriais', this.form).then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        this.$emit('onCadastro', resp.data.data);
        this.$toast.success('Importado com sucesso!');
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      }).finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>
.v-card {
  padding: 0 15px 0 15px;
}

.image-container {
  border: solid 1px #888;
  border-radius: 5px;
}
</style>
