import { render, staticRenderFns } from "./SerialList.vue?vue&type=template&id=628dcea6&scoped=true&"
import script from "./SerialList.vue?vue&type=script&lang=js&"
export * from "./SerialList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628dcea6",
  null
  
)

export default component.exports