<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <v-spacer></v-spacer>
        <span class="text-h5">Detalhes da Venda</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="loading">
        Carregando...
      </v-card-text>
      <v-card-text v-else align="start" justify="start" style="height: 460px; overflow-x: auto; font-size: 16px;">
        <div><b>Número:</b> {{ form.id }}</div>
        <div><b>Status:</b> {{ form.status }}</div>
        <div><b>Total:</b> {{ 'R$ ' + formatPrice(form.total_liquido) }}</div>
        <div><b>Forma Pgto:</b> {{ form.forma_pagamento }}</div>
        <div><b>Emissão:</b> {{ formatDate(form.created_at) }}</div>
        <br>
        <div style="width: 100%;">
          <v-card-title>Produtos:</v-card-title>
          <template v-if="form.produtos?.length">
            <v-simple-table style="border: 1px solid #ddd; border-radius: 5px; width: 100%;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Código
                    </th>
                    <th class="text-left">
                      Nome
                    </th>
                    <th class="text-left">
                      Quantidade
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(produto, index) in form.produtos" :key="index">
                    <td>{{ produto.id }}</td>
                    <td>{{ produto.nome }}</td>
                    <td>{{ produto.quantidade }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
      </div>
      </v-card-text>
      <v-card-actions>
          <v-btn text color="warning" @click="$emit('close')">Fechar</v-btn>
          <v-spacer />
          <v-btn v-if="form.status === 'entregue'" color="primary" @click="resendEmail" :loading="loading">Reenviar E-Mail</v-btn>
      </v-card-actions>
    </v-card>

    <EmailResend
      :visible="resendEmailVisible"
      :form="formData"
      @close="resendEmailVisible = false"
    />
  </v-dialog>
</template>

<script>
import EmailResend from './EmailResend';

export default {
  name: 'SaleDetails',

  components: {
    EmailResend,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },

  data: () => ({
    loading: false,
    resendEmailVisible: false,
    form: {},
    formData: {},
  }),

  watch: {
    id(value) {
      value && this.consultar();
    }
  },

  methods: {
    consultar() {
      this.loading = true;
      this.$http.get('pedidos/' + this.id).then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        this.form = resp.data;
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      }).finally(() => (this.loading = false));
    },

    resendEmail() {
      this.formData = {
        email: this.form.user.email,
        id: this.id,
      };
      this.resendEmailVisible = true;
    }
  }
}
</script>

<style scoped>
.v-card {
  padding: 0 15px 0 15px;
}
</style>
