<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" max-width="600px">
    <v-card>
      <v-card-title>
        Reenviar informações do pedido no e-mail
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="E-mail"
          ref="email"
          :rules="rules.email"
          prepend-icon="email"
          v-model="formData.email"
          autocomplete="email"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="resendEmail" :loading="loading">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EmailResend',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
  },

  data: () => ({
    loading: false,
    formData: {},
    rules: {
      email: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido',
      ],
    },
  }),

  watch: {
    form(v) {
      this.formData = v;
      console.log(v)
      // value && this.consultar();
    }
  },

  methods: {
    resendEmail() {
      this.loading = true;
      this.$http.get(`pedidos/${this.formData.id}/resend-email?email=${this.formData.email}`).then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        if (resp.data.type === 'success') {
          this.$toast.success('E-Mail enviado');
          this.$emit('close');
          return;
        }
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      }).finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>
.v-card {
  padding: 0 15px 0 15px;
}

.v-card__text {
  padding: 10px 24px 10px;
}
</style>
