<template>
    <v-row>
        <v-col cols="6">
            <v-btn for="file" color="primary" class="file" @click="$refs.file.click()">
                <v-icon>mdi-upload</v-icon>
                Selecionar Arquivo
            </v-btn>
            <input type="file" accept=".csv, .txt" ref="file" id="file" name="file" @input="uploader">
        </v-col>
        <v-col cols="6">
            <span>{{ getFileName() }}</span>
            <!-- <v-img
                max-height="100"
                max-width="160"
                v-if="getUrl()"
                :src="getUrl()"
            /> -->
            <!-- <v-btn color="red" style="color: white" v-if="getUrl()" @click.native="clear" small>
                Excluir
            </v-btn> -->
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'FileInput',

    props: ['value'],

    data: () => ({
        file: null
    }),

    watch: {
        value(v) {
            this.setFile(v);
        },
    },

    mounted() {
        this.setFile(this.value);
    },

    methods: {
        setFile(value) {
            if (!value?.url && !value?.length) {
                this.file = [];
                return;
            }
            value?.length && (this.file = value[0]);
            value?.url && (this.file = value);
        },

        clear() {
            this.file = [];
            this.$emit('input', null);
        },

        getFileName() {
            if (!this.file?.url) {
                return '';
            }

            // const url = this.file.tmp
            //     ? `${this.$urlAPI}/files/tmp/${this.file.url}`
            //     : `${this.$urlAPI}/storage/${this.file.url}`;

            return this.file.fileName;
        },

        async uploader(e) {
            const files = e.target.files;
            try {
                const urlUpload = `${this.$urlAPI}/files/upload`;
                const file = files[0];

                const body = new FormData();
                body.append('file', file);
                const resp = await fetch(urlUpload, {
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                    body,
                });

                const name = file.name.split('.');
                name.pop();

                const dataResp = await resp.json();

                this.$emit('input', {
                    descricao: name.join('.'),
                    file: file,
                    fileName: file.name,
                    tmp: true,
                    url: dataResp.path,
                });
            } finally {
                //
            }
        },
    }
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

.file {
    margin: 12px 0 12px;
    cursor: pointer;
}
</style>
