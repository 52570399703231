<template>
    <AppContainer>
        <span slot="container">
            <div class="pr-2 pl-2">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/dashboard">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Seriais</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        @keypress.enter="consultar"
                        label="Pesquisar..."
                        append-icon="mdi-magnify"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" fab small @click="openCadastro">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 225"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.produto="{ item }">
                                {{ item.produto.id }} - {{ item.produto.nome }}
                            </template>

                            <template v-slot:item.numero_serial="{ item }">
                                <span>{{ "********"+item.numero_serial.substring(7,30) }}</span>
                            </template>

                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.datahora_entrada="{ item }">
                                {{ datetime(item.datahora_entrada) }}
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.datahora_saida="{ item }">
                                {{ datetime(item.datahora_saida) }}
                            </template>
                             <!-- eslint-disable-next-line -->
                            <template v-slot:item.status="{ item }">
                                {{ sanitizeStatus(item.status) }}
                            </template>
                            <!-- eslint-disable-next-line
                            <template v-slot:item.action="{ item }">
                                <v-btn icon @click.stop="excluir(item.id)" color="red">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            -->
                        </v-data-table>
                        <DataTablePaginate v-model="pagination" @input="consultar"/>
                    </v-card-text>
                </v-card>
            </div>
            <SerialForm
                :visible="visible"
                :formData="form"
                @onCadastro="onCadastro"
                @close="close"
            />
        </span>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';
import DataTablePaginate from '@/components/DataTablePaginate';
import SerialForm from './SerialForm';

export default {
    name: 'SerialList',

    components: {
        AppContainer,
        DataTablePaginate,
        SerialForm ,
    },

    data: () => ({
        visible: false,
        search: '',
        loading: true,
        dados: [],
        form: {},
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'ID - Produto', value: 'produto' },
            { text: 'Serial', value: 'numero_serial' },
            { text: 'Entrada', value: 'datahora_entrada' },
            { text: 'Saída', value: 'datahora_saida' },
            { text: 'Pedido', value: 'pedido_id' },
            { text: 'Status', value: 'status' },
            // { text: 'Ações', value: 'action', sortable: false, align: 'end'},
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            async handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';
            const params = `page=${this.pagination.current_page}&q=${this.search || ''}${sortBy}${sortOrder}`;

            this.$http.get(`seriais?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        datetime(dh, format = 'DD/MM/YYYY HH:mm:ss') {
            return dh ? this.moment(dh).format(format) : '';
        },

        close() {
            this.visible = false;
            this.form = {};
        },

        openCadastro() {
            this.form = { ativo: true };
            this.visible = true;
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        editar(row) {
            console.log(row)
            // this.form = row;
            // this.visible = true;
        },

        async excluir(id) {
            if (confirm('Deseja realmente EXCLUIR?')) {
                this.$http.delete(`seriais/${id}`).then(() => {
                    this.$toast.success('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.$toast.error('Não foi possivel concluir a operação');
                });
            }
        },

        sanitizeStatus(status) {
            if (status == 'utilizado') {
                return 'entregue';
            }
            return status;
        },
    },
}
</script>

<style scoped>
</style>
