<template>
    <AppContainer>
        <span slot="container">
            <div class="pr-2 pl-2">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/dashboard">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Vendas</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        @keypress.enter="consultar"
                        label="Pesquisar..."
                        append-icon="mdi-magnify"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="170"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <v-spacer/>
                                FILTROS ADICIONAIS
                                <v-spacer/>
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-card-text>
                                <v-row class="mt-4">
    
                                    <v-col cols="12" align="center" justify="center">
                                        <v-text-field
                                            type="date"
                                            v-model="form.start"
                                            label="Data Inicial"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col> 
                                    <v-col cols="12" align="center" justify="center">
                                        <v-text-field
                                            type="date"
                                            v-model="form.end"
                                            label="Data Final"
                                            outlined
                                            dense
                                            hide-details
                                        />
                                    </v-col> 
                                <!--<v-col cols="12" align="center" justify="center">
                                        <v-datetime-picker
                                            label="Data Inicial"
                                            v-model="form.start"
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm:ss"
                                            okText="Confirmar"
                                            clearText="Limpar"
                                            :datePickerProps="{
                                                'locale': 'pt-BR',
                                                'format': '24hr',
                                                'ampm-in-title': false,
                                            }"
                                            :textFieldProps="{
                                                'outlined': true,
                                                'dense': true,
                                                'hide-details': true,
                                            }"
                                        />
                                    </v-col>
                                    <v-col cols="12" align="center" justify="center">
                                        <v-datetime-picker
                                            label="Data Final"
                                            v-model="form.end"
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm:ss"
                                            okText="Confirmar"
                                            clearText="Limpar"
                                            :datePickerProps="{
                                                'locale': 'pt-BR',
                                                'format': '24hr',
                                                'ampm-in-title': false,
                                            }"
                                            :textFieldProps="{
                                                'outlined': true,
                                                'dense': true,
                                                'hide-details': true,
                                            }"
                                        />
                                    </v-col>-->
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="form.formasPagamento"
                                            :items="formasPagamento"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Forma de Pagamento"
                                            multiple
                                            outlined
                                            chips
                                            dense
                                            small-chips
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="form.status"
                                            :items="status"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Status do Pedido"
                                            multiple
                                            outlined
                                            chips
                                            dense
                                            small-chips
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>

                            </v-card-text>

                            <v-card-actions class="mt-8">
                                <v-btn color="gray" text @click="menu = false">Voltar</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="consultar" class="ml-1">
                                    <v-icon>mdi-magnify</v-icon>&nbsp;Consultar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-toolbar>

                <div class="ma-1 toolbar-totais font-size-sm">
                    <div>
                        <b>Quantidade:</b> {{ total.qtdVendas }}
                    </div>
                    <div>
                        <b>Canceladas:</b> {{ total.qtdCancelada }}
                    </div>
                    <div>
                        <b>Pendentes:</b> {{ total.qtdPendente }}
                    </div>
                    <div>
                        <b>Pagas:</b> {{ total.qtdPaga }}
                    </div>
                    <div>
                        <b>Entregues:</b> {{ total.qtdEntregue }}
                    </div>
                    <div>
                        <b>Total Pendente:</b> R$ {{ formatPrice(total.vlrPendente) }}
                    </div>
                    <div>
                        <b>Total Pago:</b> R$ {{ formatPrice(total.vlrPago) }}
                    </div>
                </div>

                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <!--  @click:row="openDetails" -->

                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 295"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.total_bruto="{ item }">
                                R$ {{ formatPrice(item.total_bruto) }}
                            </template>
                             <!-- eslint-disable-next-line -->
                            <template v-slot:item.total_liquido="{ item }">
                                R$ {{ formatPrice(item.total_liquido) }}
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.created_at="{ item }">
                                {{ datetime(item.created_at) }}
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.uuid="{ item }">
                                <v-btn color="primary" icon @click.stop="openCheckout(item.uuid)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>

                            <!-- <v-btn v-if="item.status === 'entregue'" color="primary" icon>
                                    <v-icon>mdi-email-outline</v-icon>
                                </v-btn> -->

                            </template>
                            <!-- eslint-disable-next-line -->
                            <!-- <template v-slot:item.action="{ item }">
                                <v-btn icon @click.stop="excluir(item.id)" color="red">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template> -->
                        </v-data-table>
                        <DataTablePaginate v-model="pagination" @input="consultar"/>
                    </v-card-text>
                </v-card>
            </div>
            <SaleDetails :visible="visible" :id="selectedId" @close="close"/>
        </span>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';
import DataTablePaginate from '@/components/DataTablePaginate';
import SaleDetails from './SaleDetails';

export default {
    name: 'SalesPage',

    components: {
        SaleDetails,
        AppContainer,
        DataTablePaginate,
    },

    data: () => ({
        visible: false,
        menu: false,
        search: '',
        loading: true,
        dados: [],
        form: {},
        selectedId: '',
        headers: [
            { text: 'Pedido', value: 'id' },
            { text: 'Data/Hora', value: 'created_at' },
            { text: 'Cliente', value: 'user.name', sortable: false, },
            { text: 'Total', value: 'total_liquido' },
            { text: 'Forma Pgto', value: 'forma_pagamento' },
            { text: 'Status', value: 'status' },
            { text: 'Ações', value: 'uuid' },
            // { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        status: [
            'pendente',
            'cancelado',
            'pago',
            'entregue',
        ],
        formasPagamento: [
            //'nenhuma',
            'pix',
            //'mercadopago',
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        totalizadores: [],
        options: {},
    }),

    mounted() {
        this.form.start = ''; // this.moment().startOf('month').format('DD/MM/YYYY HH:mm:ss');
        this.form.end = ''; //this.moment().endOf('day').format('DD/MM/YYYY HH:mm:ss');
        this.form.status = []; //  this.status;
        this.form.formasPagamento =  []; //this.formasPagamento;
    //    this.dataInicial = this.moment().startOf('day').format('DD/MM/YYYY') + ' 00:00:00';
        this.consultar();
    },

    watch: {
        options: {
            async handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    computed: {
        total() {
            const quantidade = this.totalizadores;
            const pendentes = this.totalizadores.filter(e => e.status === 'pendente');
            const canceladas = this.totalizadores.filter(e =>  e.status === 'cancelado');
            const entregues = this.totalizadores.filter(e => e.status === 'entregue');
            const pagas = this.totalizadores.filter(e => ['pago', 'entregue'].includes(e.status));

            return {
                qtdVendas: quantidade.reduce((a, b) => a + +b.quantidade, 0) || 0,
                qtdCancelada: canceladas.reduce((a, b) => a + +b.quantidade, 0) || 0,
                qtdPendente: pendentes.reduce((a, b) => a + +b.quantidade, 0) || 0,
                qtdEntregue: entregues.reduce((a, b) => a + +b.quantidade, 0) || 0,
                qtdPaga: pagas.reduce((a, b) => a + +b.quantidade, 0) || 0,
                vlrPendente: pendentes.reduce((a, b) => a + +b.total_liquido, 0) || 0,
                vlrPago: pagas.reduce((a, b) => a + +b.total_liquido, 0) || 0,
            };
        },

        getParams() {
            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';

            const start = this.form.start
                ? this.form.start  //this.moment(this.form.start, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : '';
            const end = this.form.end
                ? this.form.end  //this.moment(this.form.end, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : '';

            const status = this.form.status.join(',');
            const formasPgto = this.form.formasPagamento.join(',');

            const filters = `&start=${start}&end=${end}&status=${status}&formasPagamento=${formasPgto}`;

            return `page=${this.pagination.current_page}${filters}&q=${this.search || ''}${sortBy}${sortOrder}`;
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            const params = this.getParams;

            this.$http.get(`pedidos?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
                this.loading = false;
            });

            this.getTotais();
        },

        getTotais() {
           const params = this.getParams;

            this.$http.post(`pedidos/get-totais?${params}`).then(resp => {
               this.totalizadores = resp.data;
            })
            .catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        datetime(dh, format = 'DD/MM/YYYY HH:mm:ss') {
            return dh ? this.moment(dh).format(format) : '';
        },

        close() {
            this.visible = false;
            this.selectedId = '';
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        openDetails(row) {
            this.selectedId = row.id;
            this.visible = true;
        },

        openCheckout(uuid) {
            // const url = (document.URL.indexOf('localhost') !== -1)
            //    ? `http://lojafacil.localhost:8000/ecommerce/checkout/${uuid}`
            //    : `https://cliente.lojafacil.app/ecommerce/checkout/${uuid}`;
            const url = document.location.origin + `/ecommerce/checkout/${uuid}`;
            window.open(url);
        },

        async excluir(id) {
            if (confirm('Deseja realmente EXCLUIR?')) {
                this.$http.delete(`pedidos/${id}`).then(() => {
                    this.$toast.success('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.$toast.error('Não foi possivel concluir a operação');
                });
            }
        },
    },
}
</script>

<style scoped>
.toolbar-totais {
    background-color: #fff;
    height: 60px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

@media only screen and (max-width: 600px) {
  .font-size-sm {
    font-size: 14px;
  }
}
</style>
